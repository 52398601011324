import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import Card from "../components/card";

const ProjectPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProjects {
        edges {
          node {
            summary {
              summary
            }
            title
            url
          }
        }
      }
    }
  `);

  return (
    <div>
      <Layout>
        <Head title="Projects" />
        <div class="section-page-header">
          <h2>Projects</h2>
        </div>

        <div class="row">
          {data.allContentfulProjects.edges.map(edge => {
            return <Card data={edge.node} />;
          })}
        </div>
      </Layout>
    </div>
  );
};

export default ProjectPage;
